<template>
    <div class="login-page">
      <div class="back-home" @click="toHome"><van-icon name="arrow-left" /><span>返回</span></div>
      <div class="row" :class="`login-model${pageStatus}`">
          <span class="login-title">{{ pageModeData.title }}</span>
          <span class="login-des" @click="changeP(pageModeData.toP)">{{ pageModeData.des }}</span>
      </div>
      <div class="row mt-20 login-form">
        <div class="row login-form-item mt-10">
          <div class="form-label">账号</div>
          <div class="form-input"><div class="lf-input"><input type="text" maxlength="11"  autocomplete="new-password" placeholder="请输入您的账号" v-model="loginForm.phone" /></div></div>
        </div>
        <!-- 账号密码登录 -->
        <div class="row login-form-item mt-20" v-if="pswInput.includes(pageStatus)">
          <div class="form-label">密码</div>
          <div class="form-input"><div class="lf-input"><input type="password" autocomplete="new-password" placeholder="请输入您的密码" v-model="loginForm.password" /></div></div>
        </div>
        <div class="row forget-box" v-if="pswInput.includes(pageStatus)"><span @click="changeP(3)">忘记密码?</span></div>
        <!-- 手机号登录 -->
        <div class="row login-form-item mt-20" v-if="smsCodeInput.includes(pageStatus)">
          <div class="form-label">验证码</div>
          <div class="form-input"><div class="lf-input"><input type="text" maxlength="4" autocomplete="new-password" placeholder="请输入您的验证码" v-model="loginForm.smsCode" /></div></div>
          <div class="login-mobile-code" :class="timeCutdown == 60 ? '' : 'getcode-ing'" @click="sengMsgCode">{{sendCode}}</div>
        </div>
        <!-- 忘记密码 -->
        <div class="row login-form-item mt-20" v-if="newPswInput.includes(pageStatus)">
          <div class="form-label">设置新密码</div>
          <div class="form-input"><div class="lf-input"><input type="password" autocomplete="new-password" placeholder="请输入您的新密码" v-model="loginForm.password" /></div></div>
        </div>
      </div>
      <div class="row mt-40">
        <div class="login-btn" @click="onSubmit">{{ pageModeData.btnText }}</div>
      </div>
      <div class="row mt-20" v-if="pageStatus != 3">
        <div class="reginster-btn">还没有账号？<span @click="to_register">立即注册</span></div>
      </div>
    </div>
</template>

<script>
import { login,loginPhone, forgetUserPwd,getPhoneCode,info } from "@/api/user";
import { setToken } from "@/utils/auth";
import { checkPhone } from "@/utils/comm";
const reg_mobile = /^0?(13|14|15|16|17|18|19)[0-9]{9}$/;

export default {
  data() {
    return {
      smsCodeInput:[2,3],
      pswInput:[1],
      newPswInput:[3],
      pageStatus:1,   // 1=>账号密码登录，2=>手机号登录, 3=>忘记密码
      pageModel:{
        'model_1':{
          title:'账号密码',
          des:'手机号',
          btnText:'登录',
          toP:2,
        },
        'model_2':{
          title:'手机号',
          des:'账号密码',
          btnText:'登录',
          toP:1,
        },
        'model_3':{
          title:'忘记密码？',
          des:'请重新编辑密码，建议以简单好记为准',
          btnText:'提交',
        }
      },
      sendCode:'获取验证码',
      isGetMCode:false,
      ableToSend: false,
      timeCutdown: 60,
      sendTimer: '',

      codeUrl: "",
      reform:1,
      form: {
        username: "",
        password: "",
        code:null,
        uuid:null,
      },
      loginForm:{
        phone: "",
        password: "",
        smsCode:'',
        code:null,
        uuid:null,
      },
      formre:{},
      themeVars:{}
    };
  },
  created() {
    this.resetForm();
  },
  mounted() {},
  computed: {
    pageModeData(){
      return this.pageModel[`model_${this.pageStatus}`];
    },
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    },
    'loginForm.phone'(){
      if (reg_mobile.test(this.loginForm.phone) && this.timeCutdown === 60) {
        this.ableToSend = true;
      }
    },
    userCode () {
      if (reg_mobile.test(this.loginForm.phone) && this.timeCutdown === 60) {
        this.ableToSend = true;
      }
      if (this.loginForm.code.length !== 4) {
        this.userCodeTips = '短信验证码格式有误'
      } else {
        this.userCodeTips = ''
      }
    },
    timeCutdown () {
      if (this.timeCutdown <= 0) {
        this.timeCutdown = 60
        this.sendCode = '获取验证码'
        clearInterval(this.sendTimer)
      }
    }
  },
  methods: {
    toHome(){
      this.$router.push("/index");
    },
    changeP(toP){
      // 重置所有输入框信息
      this.resetForm();
      this.pageStatus = toP;
    },
    resetForm(){
      this.loginForm.phone = '';
      this.loginForm.password = '';
      this.loginForm.smsCode = '';
    },
    // 发送验证码
    sengMsgCode () {
      var self = this;
      if(!self.ableToSend){
        return false;
      }
      self.requestCode();
    },
    // 请求验证码
    requestCode () {
      if (this.loginForm.phone === '') {
        this.$toast.fail('手机号不能为空')
        return false
      }
      if (!reg_mobile.test(this.loginForm.phone)) {
        this.$toast.fail('手机号格式有误，请重新输入')
        return false
      }
      let self = this
      self.ableToSend = false;
      let params = {
        phone: self.loginForm.phone
      }
      this.isGetMCode = true;
      getPhoneCode(params).then(function (res) {
        if (res.code == 200) {
          self.timeCutdown = res.data ? res.data : 60
          self.ableToSend = false;
          self.sendTimer = setInterval(function () {
            if ( self.timeCutdown > 0) {
              self.timeCutdown--
              self.sendCode = self.timeCutdown + '秒后重新发送';
              self.ableToSendCompleted=true  
            }
            if(self.timeCutdown  == 0){
              self.ableToSend = true;
              self.ableToSendCompleted=false
            }
          }, 1000);
          self.$notify.success(res.msg)
        } else {
          self.$toast.fail(res.msg)
        }
      }).catch(() => {
      })
    },
    // 注册
    to_register(){
        this.$router.push("/register");
    },
    // 提交行为
    onSubmit() {
      // 发请求，获取到后台的token，保存到cookies里，获取当前登录的用户信息
      if(!this.loginForm.phone){
        this.$toast.fail('手机号不能为空');
        return false;
      }else if(!checkPhone(this.loginForm.phone)){
        this.$toast.fail('手机号格式不正常');
        return false;
      }
      if(!this.loginForm.password && (this.pswInput.includes(this.pageStatus) || this.newPswInput.includes(this.pageStatus))){
        this.$toast.fail('密码不能为空');
        return false;
      }
      if(!this.loginForm.smsCode && this.smsCodeInput.includes(this.pageStatus)){
        this.$toast.fail('验证码不能为空');
        return false;
      }
      if(this.pageStatus == 1) this.toLogin();
      if(this.pageStatus == 2) this.toLoginPhone();
      if(this.pageStatus == 3) this.forgetUserSub();
      
    },
    // 账号登录
    toLogin(){
      let where = {
        username:this.loginForm.phone,
        password:this.loginForm.password
      }
      login(where).then((res)=> {
        if(res.code == 200){
          this.loginSuccess(res)
        }
      })
    },
    // 验证码登录
    toLoginPhone(){
      let where = {
        phone:this.loginForm.phone,
        smsCode:this.loginForm.smsCode
      }
      loginPhone(where).then( (res)=> {
        if(res.code == 200){
          this.loginSuccess(res)
        }
      })
    },
    // 忘记密码
    forgetUserSub(){
      let where = {
        phone:this.loginForm.phone,
        smsCode:this.loginForm.smsCode,
        password:this.loginForm.password
      }
      forgetUserPwd(where).then( (res)=> {
        if(res.code == 200){
          this.$toast.loading(res.msg);
          this.$router.go(0)
          // // this.loginSuccess(res);
          // this.$nextTick(()=>{
          //   setTimeout(()=>{
          //     this.changeP(1);
          //   },500)
          // })
        }
      })
    },
    getUserInfo(loginRes){
      info().then(res => {      
          if(res.code == 200){
            sessionStorage.setItem('userAllInfo',JSON.stringify(res.data));
            this.$router.push("/index");
          }
      }).catch(err=>{
      })
    },
    // 登录成功后缓存处理
    loginSuccess(res){
      this.$toast.loading('登录成功');
      setToken(res.token);
      this.$nextTick(()=>{
        // 路由跳转
        this.getUserInfo();
      })
    }
  },
};
</script>
<style lang="less">
</style>